import * as React from "react";
import styled from "styled-components";
import { theme } from "../theme";

interface SectionTitleProps {
  children: any;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => {
  return <Title>{children}</Title>;
};

export { SectionTitle };

const Title = styled.div`
  padding-left: ${theme.space[2]};

  border: 10px ${theme.colors.sectionColor};
  border-left-style: solid;

  font-size: ${theme.fontSizes[0]};
  line-height: ${theme.fontSizes[0]};
  font-weight: 600;

  color: ${theme.colors.mainTextColor};

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    font-size: ${theme.fontSizes[2]};
    line-height: ${theme.fontSizes[3]};
  }
`;

import * as React from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import { ActivitiesPageQuery } from "../../../types/graphql-types";
import Layout from "../../components/Layout";
import { Meta } from "../../components/Meta";
import { theme } from "../../components/theme";
import { Avatar } from "@material-ui/core";
import MediaQuery from "react-responsive";
import { SectionTitle } from "../../components/SectionTitle";
import { Helmet } from "react-helmet";

interface ActivitiesProps {
  data: ActivitiesPageQuery;
}

const Activities: React.FC<ActivitiesProps> = ({ data }) => {
  const activityList = data.allContentfulActivity.edges;

  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>{`mogFlowts - Activities`}</title>
      </Helmet>

      <>
        {/* SP */}
        <MediaQuery query={`(max-width: ${theme.ConstBreakPoint.MAX_SP})`}>
          <Container>
            <TitleContainer>
              <SectionTitle>ACTIVITIES</SectionTitle>
            </TitleContainer>
            <ActivityContainerSP>
              {data.allContentfulActivity.edges.map((item, index) => {
                return (
                  <ActiveContentSP
                    key={index}
                    onClick={() =>
                      navigate(`/activity/${item.node.contentful_id}`)
                    }
                  >
                    <img
                      key={item.node.contentful_id}
                      src={`${item.node.heroImage.file.url}?w=500`}
                      alt={item.node.contentful_id}
                    />
                    <div>{item.node.date}</div>
                    <div>{item.node.title}</div>
                    <div>
                      <Avatar
                        alt="Author"
                        src={`${item.node.author.avatorImage.file.url}?w=50`}
                      />
                      {item.node.author.name}
                    </div>
                  </ActiveContentSP>
                );
              })}
            </ActivityContainerSP>
          </Container>
        </MediaQuery>

        {/* PC */}
        <MediaQuery query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}>
          <Container>
            {activityList.map((activity, index) => {
              return (
                <ActivityCard
                  key={activity.node.contentful_id}
                  imageURL={activity.node.heroImage.file.url}
                  onClick={() =>
                    navigate(`/activity/${activity.node.contentful_id}`)
                  }
                >
                  {/* image */}
                  <div />
                  <div>
                    <div>{activity.node.date}</div>
                    <div>{activity.node.title}</div>
                    <div>
                      <div>
                        <Avatar
                          alt="Author"
                          src={`${activity.node.author.avatorImage.file.url}?w=100`}
                        />
                        <div>{activity.node.author.name}</div>
                      </div>
                    </div>
                  </div>
                </ActivityCard>
              );
            })}
          </Container>
        </MediaQuery>
      </>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ActivitiesPage {
    allContentfulActivity(sort: { fields: date, order: DESC }) {
      edges {
        node {
          contentful_id
          title
          date
          author {
            name
            avatorImage {
              file {
                url
              }
            }
          }
          heroImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Activities;

const TopLogo = styled.div`
  width: 100vw;
  background-color: grey;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 15px auto 0;
    width: ${theme.width.main};
  }
`;

const Container = styled.div`
  padding: 30px 0 0 0;

  margin: 0 auto;

  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 40px 15px 80px;
    width: ${theme.width.main};
  }
`;

const TitleContainer = styled.div`
  padding-left: 15px;
`;

interface ActivityLogContainerProps {
  imageURL: string;
}

const ActivityContainerSP = styled.div`
  margin: 15px auto 30px;

  width: 100%;
`;

const ActiveContentSP = styled.div`
  padding: 15px;

  border: 0.5px ${theme.colors.grey20};
  border-bottom-style: solid;

  // date
  & > div:nth-child(2) {
    font-size: 0.6em;
  }

  // tuitle
  & > div:nth-child(3) {
    color: ${theme.colors.grey60};
    font-weight: 600;
  }

  // UserInfo
  & > div:nth-child(4) {
    margin-top: auto;
    margin-bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > :nth-child(1) {
      margin-right: 15px;
    }
  }

  & > img {
    width: 100%;
    max-height: 70vw;
    justify-self: center;
    border-radius: 8px;
    cursor: pointer;
    object-fit: cover;
    border: 1px solid ${theme.colors.grey20};
  }
`;

const ActivityCard = styled.div<ActivityLogContainerProps>`
  margin: 30px auto;
  padding: 15px;

  width: 100%;
  height: 200px;

  border: 1px solid ${theme.colors.grey20};
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  cursor: pointer;

  // iamge
  & > div:nth-child(1) {
    margin-right: 15px;

    width: 240px;
    height: 160px;
    border-radius: 8px;
    object-fit: contain;
    user-select: none;

    background-image: ${(props) => `url(${props.imageURL})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  // data
  & > div:nth-child(2) {
    width: calc(100% - 240px);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    word-wrap: break-word;

    // date
    & > div:nth-child(1) {
      font-size: 0.8em;
    }

    // title
    & > div:nth-child(2) {
      color: ${theme.colors.grey60};

      font-weight: 600;
    }

    // etc
    & > div:nth-child(3) {
      margin-top: auto;
      margin-bottom: 0;

      width: 100%;

      align-self: flex-end;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      // user info
      & > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        // avator
        & > :nth-child(1) {
          margin-right: 15px;
        }

        // name
        & > :nth-child(2) {
          color: ${theme.colors.grey60};
        }
      }

      //liked
    }
  }
`;
